<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
				<div class="page_links">
					当前位置：
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/uc_goods' }">我的商品</el-breadcrumb-item>
						<el-breadcrumb-item>关联产品</el-breadcrumb-item>
					</el-breadcrumb>
				</div>

        <div class="wrap_body_content">
          <div class="wrap_body_content_left">
            <div v-if="product_list.length > 0">
              <div class="wrap_body_content_left_goods" :class="{active: item.id == current_product.id}" v-for="item in product_list" @click="handleChangeProduct(item)">
                <div style="flex-shrink: 0;">
                  <img class="wrap_body_content_left_goods_pic" :src="item.img" />
                </div>
                <div class="wrap_body_content_left_goods_desc">
                  <p class="title">{{item.name}}</p>
                </div>
              </div>
            </div>
            <div v-else>暂无产品</div>
          </div>

          <div class="wrap_body_content_right">
            <goodsConnectBox ref="goodsConnect" />
          </div>
        </div>

<!--				<goodsConnectBox ref="goodsConnect" />-->

		</div>
		<myFoot></myFoot>
	</div>
</template>

<script>
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import goodsConnectBox from '../../components/goodsConnectBox/goodsConnectBox.vue';
import {mapActions} from "vuex";

export default {
	name: "goodsConnect",
	data() {
		return {
			isLoading:true,
      order_ids:'',
      product_list: [],
      current_product: {},
		}
	},
	components:{
		myHead,myFoot,goodsConnectBox
	},
	created(){
	},
  mounted() {
    this.order_ids = this.$route.query.id
    this.init();
    // this.$refs.goodsConnect.init(this.$route.query.id)
  },
  methods: {
    ...mapActions({
      getRelativeProductList:"ucenter/getRelativeProductList",
    }),
    init(){
      this.getRelativeProductList({
        data: {ids: this.order_ids},
        success:res=>{
          this.product_list = res.data
          if(res.data.length > 0){
            this.handleChangeProduct(res.data[0])
          }
        }
      })
    },
    handleChangeProduct(row){
      this.current_product = row;
      this.$refs.goodsConnect.init(row.id)
    },
	}
}
</script>

<style scoped lang="scss">
.wrap{
  &_body{
    height: 100%;
    margin: 0 auto;
    min-width: 1200px;

    .page_links{line-height: 1;font-size: 14px;color: #999999;display: flex;margin-top: 25px;}
    .page_links .el-breadcrumb__inner{font-weight: bold !important;color: #333 !important;}
    .page_links .el-breadcrumb__inner.is-link{font-weight: initial !important;color: #999 !important;}
    .page_links .el-breadcrumb__inner.is-link:hover{color: #f0423f !important;text-decoration: underline;}
    &_content{
      display: flex;
      gap: 20px;
      margin-top: 30px;
      &_left{
        width: 350px;
        padding: 10px;
        height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
        background: #ffffff;
        box-sizing: border-box;

        &_goods{
          display: flex;
          border: #c0c4cc solid 1px;
          border-radius: 6px;
          margin: 10px 0;
          padding: 20px 10px;
          box-sizing: border-box;
          &_pic{
            width: 40px;
            height: 40px;
            border-radius: 6px;
            object-fit: cover;
          }
          &_desc{
            width: 200px;
            padding-left: 20px;
            flex-shrink: 0;
            font-size:12px;
            box-sizing: border-box;
            .title{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
        }
        .active{
          border-color: #fa5757;
        }
      }


      &_left::-webkit-scrollbar {
        width: 3px;
      }

      &_left::-webkit-scrollbar-thumb {
        background: #DDDDDD;
        border-radius: 3px;
      }

      &_left::-webkit-scrollbar-track {
        background: #F2F2F2;
        border-radius: 3px;
      }

      &_right{
        width: 1200px;
        background: #ffffff;
      }
    }
  }
}
//.tongkuan_mask{width: 100%;height: 100%;position: fixed;top: 0;left: 0;background: rgba(0, 0, 0, .5);z-index: 999;transition: all .5s;visibility: hidden;opacity: 0;}
//.tongkuan_mask.active{visibility: visible;opacity: 1;}
//.tongkuan_mask .tongkuan_main{width: 1200px;height: 800px;box-sizing: border-box;padding: 20px 30px;background: #fff;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-70%);visibility: hidden;opacity: 0;transition: all .5s;display: flex;flex-direction: column;}
//.tongkuan_mask.active .tongkuan_main{transform: translate(-50%,-50%);visibility: visible;opacity: 1;}
//.tongkuan_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
//.tongkuan_main .title{font-size: 20px;line-height: 1;}
//.tongkuan_main .title2{margin-top: 10px;font-size: 24px;text-align: center;line-height: 1;}
//.tongkuan_main .search_wrap{margin: 10px 50px;}
//.tongkuan_main .content_wrap{display: flex;flex-direction: column;flex: 1;overflow-y: auto;}
//.tongkuan_main .tk_content{flex: 1;height: 0;overflow: hidden auto;display: flex;flex-wrap: wrap;align-items: flex-start;padding-bottom: 10px;padding-left: 4px;}
//.tk_content .product{width: 210px;height: auto;margin: 18px 18px 0 0;border-radius: 6px;box-shadow: 0 0 5px 0 #eee;overflow: hidden;}
//.tk_content .product:nth-child(5n){margin-right: 0;}
//.tk_content .product .img_box{width: 100%;height: 210px;overflow: hidden;}
//.tk_content .product .title{margin: 10px 10px 0;font-size: 14px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;cursor: pointer;}
//.tk_content .product .title:hover{color: var(--red);text-decoration: underline;}
//.tk_content .product .price_line{padding: 10px;display: flex;justify-content: space-between;align-items: center;}
//.tk_content .product .price_line .price{font-size: 14px;color: var(--red);}
//.turn_page{display: flex;justify-content: flex-end;margin-top: 20px;}
//.connect_main{margin-top: 30px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 40px;background: #fff;line-height: 1;}
//.goods_info{margin-top: 26px;display: flex;position: relative;}
//.goods_info .img_box{width: 48px;height: 48px;background: #eee;border-radius: 6px;overflow: hidden;}
//.goods_info .con_box{flex: 1;width: 0;margin-left: 10px;font-size: 12px;}
//.goods_info .con_box .name{line-height: 16px;}
//.goods_info .con_box .id{color: #999999;margin-top: 8px;}
//.goods_info .search_btn{position: absolute;right: 50px;top: 50%;transform: translateY(-50%);z-index: 22;}
//.goods_info .search_btn:hover ,.goods_info .search_btn:focus{border-color: var(--red);color: var(--red);}
//.table_wrap{margin-top: 30px;font-size: 12px;}
//.get_config{color: var(--red);cursor: pointer;}
//.get_config:hover{text-decoration: underline;}
//.table_wrap .pingtai{width: 80px;margin: 0 auto;text-align: left;box-sizing: border-box;padding-left: 20px;}
//.add_line{display: flex;justify-content: space-between;align-items: flex-end;margin: 30px 0 20px;}
//.add_line .add_btn{width: 102px;height: 35px;box-sizing: border-box;border: 1px solid var(--red);border-radius: 4px;cursor: pointer;display: flex;justify-content: center;align-items: center;font-size: 14px;color: var(--red);}
//.add_line .clear{font-size: 14px;color: #999999;display: flex;align-items: center;cursor: pointer;}
//.add_line .clear .iconfont{font-size: 14px;margin-right: 8px}
//.config_wrap .title_wrap{width: 100%;height: 50px;background: #f5f5f5;box-sizing: border-box;border: 1px solid #eee;display: flex;align-items: center;font-size: 14px;color: #999}
//.config_wrap .title_wrap .t1{width: 320px;margin: 0 16px;}
//.config_wrap .title_wrap .t2{width: 80px;text-align: center;}
//.config_wrap .title_wrap .t3{width: 120px;margin: 0 30px 0 20px;text-align: center;}
//.config_wrap .title_wrap .t4{flex: 1;width: 0;}
//.config_wrap .content_wrap{margin-top: 20px;padding-bottom: 40px;border-bottom: 1px solid #eee;height: 220px;overflow-y: auto;overflow-x: hidden;font-size: 14px;}
//.content_wrap .content_line{display: flex;align-items: flex-start;margin-top: 26px;}
//.content_wrap .content_line:first-child{margin-top: 0;}
//.content_line .b1{width: 320px;margin: 0 16px;line-height: 20px;box-sizing: border-box;padding-right: 50px;}
//.content_line .b2{width: 80px;text-align: center;}
//.content_line .b3{width: 120px;margin: 0 30px 0 20px;display: flex;justify-content: center;align-items: center;}
//.content_line .b4{flex: 1;width: 0;display: flex;}
//.content_line .b3 .t{margin-right: 10px;}
//.btn_wrap{margin-top: 20px;display: flex;justify-content: flex-end;}
//.btn_wrap .btn{width: 90px;height: 32px;border-radius: 4px;display: flex;justify-content: center;align-items: center;margin-left: 20px;cursor: pointer;font-size: 12px;box-sizing: border-box;}
//.btn_wrap .btn:nth-child(1){background: var(--red);color: #fff;}
//.btn_wrap .btn:nth-child(2){border: 1px solid #ddd;color: #333333;}
//
//.tongkuan_main .el-button--primary ,.tongkuan_main .el-button--primary:hover{background-color: var(--red) !important;border-color: var(--red) !important;}
//
//.sku_change_log_mask{width: 100%;height: 100%;position: fixed;top: 0;left: 0;background: rgba(0, 0, 0, .5);z-index: 999;transition: all .5s;visibility: hidden;opacity: 0;}
//.sku_change_log_mask.active{visibility: visible;opacity: 1;}
//.sku_change_log_mask .sku_change_log_main{width: 1200px;height: 650px;box-sizing: border-box;padding: 20px 30px;background: #fff;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-70%);visibility: hidden;opacity: 0;transition: all .5s;display: flex;flex-direction: column;}
//.sku_change_log_mask.active .sku_change_log_main{transform: translate(-50%,-50%);visibility: visible;opacity: 1;}
//.sku_change_log_mask .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
//.sku_change_log_mask .title{font-size: 20px;line-height: 1;}
</style>
<style>

//.page_links{line-height: 1;font-size: 14px;color: #999999;display: flex;margin-top: 25px;}
//.page_links .el-breadcrumb__inner{font-weight: bold !important;color: #333 !important;}
//.page_links .el-breadcrumb__inner.is-link{font-weight: initial !important;color: #999 !important;}
//.page_links .el-breadcrumb__inner.is-link:hover{color: #f0423f !important;text-decoration: underline;}
//
//.tongkuan_main .search_wrap .el-input__inner{border-radius: 6px;font-size: 16px;}
//.tongkuan_main .search_wrap .el-input__suffix{right: 1px;display: flex;top: 1px;border-radius: 0 6px 6px 0;height: 38px;overflow: hidden;border-left: 1px solid #ddd;}
//.tongkuan_main .search_wrap .el-input__suffix .el-input__suffix-inner{width: 80px;height: 38px;}
//.tongkuan_main .search_wrap .el-input__suffix .el-input__suffix-inner .icons{width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;background: #f5f5f5;cursor: pointer;}
//.tongkuan_main .search_wrap .el-input__suffix .el-input__suffix-inner .icons .iconfont{font-size: 16px;color: #999;}
//.table_wrap .el-table::before{display: none;}
//.table_wrap .el-table .el-table_1_column_2 .cell .num{color: #999;}
//.table_wrap .el-table th.el-table__cell{background: #fff6f7;border-bottom: none !important;color: #666666;padding: 12px 0;}
//.table_wrap .el-table .el-table__cell{padding: 24px 0;}
//.table_wrap .el-table .cell{padding: 0 20px;text-align: center;font-size: 12px;color: #333;}
//.table_wrap .el-table .el-table_1_column_1 .cell{text-align: center;}
//.table_wrap .el-table .el-table_1_column_2 .cell{padding: 0 10px;}
//.table_wrap .el-table th.el-table_1_column_2 .cell{text-align: left;}
//.table_wrap .el-table td.el-table__cell, .table_wrap .el-table th.el-table__cell.is-leaf{border-bottom: none;}
//
//.config_table .el-table__header-wrapper{border: 1px solid #eee !important;box-sizing: border-box;}
//.config_table .el-table th.el-table__cell{background: #f5f5f5;border: none !important;color: #999;padding: 13.5px 0;}
//.config_table .el-table td.el-table__cell{border-bottom: none !important;}
//.config_table .el-table .cell{padding: 0 16px !important;}
//.config_table .el-table td.el-table__cell div{width: 100%;}
//.config_table .el-table__body-wrapper::-webkit-scrollbar{width:4px;}
//.config_table .el-table__body-wrapper::-webkit-scrollbar-thumb{background: #DDDDDD;border-radius: 4px;}
//.config_table .el-table__body-wrapper::-webkit-scrollbar-track{background: #F2F2F2;border-radius: 4px;}
//
//.image-uploader{position: absolute;left: 0;top: 0;width: 100%;height: 100%;opacity: 0;}
//.search-image{width: 140px;height: 140px;object-fit: contain;margin: 20px auto;display: block;}
//.search_wrap .search_box{height: 50px;box-sizing: border-box;background-color: #ffffff;border-radius: 4px 0vw 0vw 4px;border: solid 2px #fa5757;display: flex;align-items: center;position: relative;}
//.search_wrap .search_box .type{width: 84px;color: #333333;cursor: pointer;text-align: center;line-height: 26px;border-right: 1px solid #ddd;}
//.search_wrap .search_box .in{flex: 1;width: 0;margin: 0 15px;color: #333333;font-size: 16px;}
//.search_wrap .search_box .el-icon-search{margin-left: 14px;font-size: 20px;color: #999;cursor: pointer;}
//.search_wrap .search_box .icon-xiangji{margin-right: 14px;font-size: 24px;color: #999;cursor: pointer;position: relative;}
//.search_wrap .search_btn{width: 132px;height: 50px;background-image: linear-gradient(180deg, #fa5757 0%, #fb6d6d 100%), linear-gradient(#ffffff, #ffffff);background-blend-mode: normal, normal;border-radius: 0vw 4px 4px 0vw;display: flex;justify-content: center;align-items: center;color: #ffffff;font-weight: bold;cursor: pointer;}
//
//
//.content_line .b4 .el-select{width: 220px;margin-right: 10px;}
//.el-input-number__decrease:hover, .el-input-number__increase:hover ,.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path ,.el-select-dropdown__item.selected{color: var(--red);}
//.input_number .el-input__inner:hover ,.el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled), .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) ,.el-input__inner:focus ,.el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner ,.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus{border-color: var(--red);}

</style>